import React from "react";
import styles from "./footerHome.module.scss";
import logoTov from "../../../assets/Logos/logoTov.png";
import logoInstagram from "../../../assets/Logos/logoInstagram.png";
import logoFacebook from "../../../assets/Logos/logoFacebook.png";
const FooterHome = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img src={logoTov} alt="logo" />
        <div>
          <div className={styles.textTerms}> Terminos y condiciones</div>
          <div className={styles.textTerms}> Politicas de privacidad</div>
        </div>
      </div>
      <div className={styles.contactContainer}>
        <div className={styles.infoContainer}>
          <div className={styles.textContact}>Contact Us</div>
          <div className={styles.textEmail}> +54 9 11 123456</div>
          <div className={styles.textEmail}>support@misiontov.com</div>
        </div>
        <div className={styles.containerLogo}>
          <img src={logoFacebook} alt="facebook" />
          <img src={logoInstagram} alt="Instagram" />
        </div>
      </div>
    </div>
  );
};

export default FooterHome;
