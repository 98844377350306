import React from "react";
import styles from "./secondSection.module.scss";
import { ProgresBar } from "./ProgresBar";

const SecondSection = () => {
  return (
    <div className={styles.contianer}>
      <div className={styles.title}> Test de Orientacion Vocacional</div>
      <span>
        Un test de orientación vocacional es una herramienta diseñada para
        ayudar a individuos a explorar y comprender sus intereses, habilidades y
        valores, con el objetivo de tomar decisiones informadas sobre su carrera
        profesional. Este examen evalúa preferencias personales, aptitudes y
        motivaciones para ofrecer recomendaciones sobre posibles opciones
        vocacionales alineadas con las características del individuo.
      </span>
      <ProgresBar />
      {/* <div
        className={styles.botonTest}
        onClick={() => {
          // navigate("/informarcion-Test-Orientacion-Vocacional");
        }}
      >
        Comenzar Test
      </div> */}
    </div>
  );
};

export default SecondSection;
