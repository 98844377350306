import React, { FC } from "react";
import styles from "./progresBar.module.scss";
import { Bar } from "./Bar";
import { PropsUserData } from "../../../../utils/interfaces";

interface PropsProgresBar {
  userData?: PropsUserData;
}
const ProgresBar: FC<PropsProgresBar> = ({ userData }) => {
  const dinamicValue = () => {
    let contTest = 0;
    if (userData?.areaMasRepetidaHabilidades) {
      contTest = contTest + 2;
    }
    if (userData?.firstAreaMMYMG) {
      contTest = contTest + 2;
    }
    if (userData?.A) {
      contTest = contTest + 2;
    }
    if (userData?.isCheckResume) {
      contTest = contTest + 2;
    }
    if (userData?.interview) {
      contTest = contTest + 2;
    }
    return contTest;
  };
  return (
    <div className={styles.container}>
      <div className={styles.containerStep}>
        <div className={styles.step}>
          <div
            className={
              dinamicValue() < 2 ? styles.circleNormal : styles.circleSelect
            }
          >
            1
          </div>
          <div className={styles.textNormal}>Test CHASIDE</div>
        </div>
        <div className={styles.step}>
          <div
            className={
              dinamicValue() < 4 ? styles.circleNormal : styles.circleSelect
            }
          >
            2
          </div>
          <div className={styles.textNormal}>Test MM & MG</div>
        </div>
        <div className={styles.step}>
          <div
            className={
              dinamicValue() < 6 ? styles.circleNormal : styles.circleSelect
            }
          >
            3
          </div>
          <div className={styles.textNormal}>Test de los 5 Grades</div>
        </div>
        <div className={styles.step}>
          <div
            className={
              dinamicValue() < 8 ? styles.circleNormal : styles.circleSelect
            }
          >
            4
          </div>
          <div className={styles.textNormal}>Resultado Final</div>
        </div>
        <div className={styles.step}>
          <div
            className={
              dinamicValue() < 10 ? styles.circleNormal : styles.circleSelect
            }
          >
            5
          </div>
          <div className={styles.textNormal}>Entrevista</div>
        </div>
      </div>
      <Bar totalStep={dinamicValue() * 10} />
    </div>
  );
};

export default ProgresBar;
