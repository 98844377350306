import React from "react";
import styles from "./isMobile.module.scss";

const IsMobilePage = () => {
  return (
    <div className={styles.containerMobile}>
      <div className={styles.boxAccount}>
        <div className={styles.school}>
          Accede a nuestra plataforma web para completar todas las fases de tu
          proceso de evaluación de manera óptima. Desde la comodidad de tu
          computadora, podrás finalizar los tests, participar en los challenges
          interactivos, y realizar entrevistas personalizadas con nuestros
          expertos. Asegúrate de aprovechar todas las herramientas y recursos
          que ofrecemos para maximizar tu rendimiento y obtener resultados
          precisos y personalizados.
        </div>
      </div>
    </div>
  );
};

export default IsMobilePage;
